import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MobileFeature = makeShortcode("MobileFeature");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MobileFeature legacyPhoneImage="relaunchPhoneFull" mdxType="MobileFeature">
      <h1>{`Quartz Relaunch`}</h1>
      <p>{`I led the development of a ground-up rewrite of the `}<a parentName="p" {...{
          "href": "https://apps.apple.com/us/app/quartz/id1437569339"
        }}>{`Quartz iOS app`}</a>{` in Swift.`}</p>
      <p><a parentName="p" {...{
          "href": "https://qz.com/1724663/putting-members-at-the-heart-of-quartz/"
        }}>{`The relaunch`}</a>{` of the Quartz app put a new focus on our members and member exclusive content. The centerpiece of the app was a new “Home” feed, curated by Quartz editors, that featured our original reporting along with contributions from our reporters, members, and contributors.`}</p>
      <blockquote {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`Quartz has an all-new app!`}<br parentName="p"></br><br parentName="p"></br>{`We’ve been working for five months on a rewrite of our iOS app that brings it in line with `}<a parentName="p" {...{
            "href": "https://t.co/EU0ueqnjNJ"
          }}>{`https://t.co/EU0ueqnjNJ`}</a>{`. Excited to finally share it.`}<a parentName="p" {...{
            "href": "https://t.co/2yJy1MfexG"
          }}>{`https://t.co/2yJy1MfexG`}</a>{` `}<a parentName="p" {...{
            "href": "https://t.co/atMGBnFKqj"
          }}>{`pic.twitter.com/atMGBnFKqj`}</a></p>{`— Hal Lee (@hal_lee) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/hal_lee/status/1182297489950543873"
        }}>{`October 10, 2019`}</a></blockquote>
    </MobileFeature>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      